function toPhoneModel(phoneObject: any) {
    const code = phoneObject?.code || '';
    const number = phoneObject?.number || '';
    const phone = phoneObject?.phone || '';

    return {
        code,
        phone: phone || (code + number)
    }
}

function toNumberModel(numberObject: any) {
    const code = numberObject?.code || '';
    const number = numberObject?.number || '';
    const phone = numberObject?.phone || '';

    return {
        code,
        number: number || phone.slice(code.length)
    }    
}

export { 
    toPhoneModel,
    toNumberModel
}