import { useAxios } from '@/composables/useAxios'
import type { BroadcastModelDTO } from "./@models/api.models";
import { API_EVENTS } from "./@models/api.events";
import { NUXT_APP_BROADCAST_API } from '~/env';

const { axios, loading } = useAxios();

export async function sendNotifRequestCreate({ request }: any) {

    const broadcastApi = NUXT_APP_BROADCAST_API;

    const message: BroadcastModelDTO = {
        eventName: API_EVENTS.EVENT_NEW_REQUEST,
        eventParams: {
            request
        }
    };

    const { success } = await axios.post(broadcastApi + `/broadcast/teachers/notifs`, message);
    return success;
}

export async function createRequest({ request }: any) {
    const { success } = await axios.post(`/requests/create`, request);
    return success;
}

export async function lookupRequest({ phone, email }: any) {
    const { success } = await axios.post(`/requests/lookup`, { phone, email });
    return success;
}